import React from 'react';
import {
    Box,
    Flex,
    Text,
    useDisclosure,
    VStack,
    Collapse,
    useColorModeValue,
    IconButton,
} from '@chakra-ui/react';
import { useLocation, Link } from 'react-router-dom';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';

const MethylationLinks = [
    {
        name: '1. SNaPshot',
        isTitle: true,
        isCollapsible: true,
        children: [
            {
                name: 'Age',
                isTitle: true,
                isSubtitle: true,
                children: [
                    {
                        name: 'Blood, Saliva, Buccal Cell',
                        to: '/portal/methyl-snapshot-bsb',
                    },
                    {
                        name: 'Semen',
                        to: '/portal/methyl-snapshot-semen',
                    },
                ],
            },
            {
                name: 'Tissue/Body Fluid',
                isTitle: true,
                isSubtitle: true,
                children: [
                    {
                        name: 'Body Fluids',
                        to: '/portal/methyl-snapshot-bodyfluid',
                    },
                    {
                        name: 'Organ Tissue',
                        to: '/portal/methyl-snapshot-organtissue',
                    },
                ],
            },
        ],
    },
    {
        name: '2. MPS',
        isTitle: true,
        isCollapsible: true,
        children: [
            {
                name: 'Age',
                isTitle: true,
                isSubtitle: true,
                children: [
                    {
                        name: 'Semen (Y-CpGs)',
                        to: '/portal/methyl-mps-semen',
                    },
                ],
            },
            {
                name: 'Tissue/Body Fluid',
                isTitle: true,
                isSubtitle: true,
                children: [
                    {
                        name: 'Body Fluids',
                        to: '/portal/methyl-mps-bodyfluid',
                    },
                ],
            },
        ],
    },
];

const AgePredictionLinks = [
    {
        name: '1. SNaPshot',
        isTitle: true,
        isCollapsible: true,
        children: [
            {
                name: 'Blood, Saliva, Buccal Swab',
                to: '/portal/age-snapshot-bsb',
            },
            { name: 'Semen', to: '/portal/age-snapshot-semen' },
            {
                name: 'Epidermis',
                to: '/portal/age-snapshot-epidermis',
            },
            { name: 'Dermis', to: '/portal/age-snapshot-dermis' },
            { name: 'Muscle', to: '/portal/age-snapshot-muscle' },
        ],
    },
    {
        name: '2. MPS',
        isTitle: true,
        isCollapsible: true,
        children: [
            { name: 'Epidermis', to: '/portal/age-mps-epidermis' },
            { name: 'Dermis', to: '/portal/age-mps-dermis' },
            { name: 'Semen', to: '/portal/age-mps-semen' },
        ],
    },
];

const AncestryLinks = [
    { name: 'Introduction', to: '/portal/ancestry-intro' },
    {
        name: 'YSTR',
        to: '/portal/ancestry-ystr',
    },
    {
        name: 'Mitochondrial DNA',
        to: '/portal/ancestry-mitochondrial',
    },
    {
        name: 'SNP',
        to: '/portal/ancestry-snp',
    },
    {
        name: 'Microhaplotype',
        to: '/portal/ancestry-microhaplotype',
    },
];

export default function LeftNav() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();
    let linksToDisplay = [];
    if (location.pathname.startsWith('/portal/methyl')) {
        linksToDisplay = MethylationLinks;
    } else if (location.pathname.startsWith('/portal/age')) {
        linksToDisplay = AgePredictionLinks;
    } else if (location.pathname.startsWith('/portal/ancestry')) {
        linksToDisplay = AncestryLinks;
    } else if (location.pathname.startsWith('/portal/protocols')) {
        linksToDisplay = [];
    }

    const bgColor = useColorModeValue('gray.100', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const contentBgColor = useColorModeValue('white', 'gray.700');
    return (
        <Box
            position="absolute"
            left={0}
            width="295px"
            height="full"
            bg="white"
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            mx="10px"
            my="1px"
            px="5px"
        >
            {linksToDisplay.length > 0 && (
                <SidebarContent onClose={onClose} links={linksToDisplay} />
            )}
        </Box>
    );
}

const SidebarContent = ({ onClose, links, ...rest }) => {
    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            h="full"
            {...rest}
        >
            <Flex
                h="5"
                alignItems="center"
                mx="1"
                my="2"
                justifyContent="space-between"
            ></Flex>
            {links.map((link) => (
                <NavItem key={link.name} link={link}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

const NavItem = ({ link }) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
    const location = useLocation();
    const active = link.to && location.pathname === link.to;

    return (
        <Box w="full" p="3px">
            {link.isSubtitle ? (
                <Text fontWeight="semibold" pl="4" py="1" fontSize="md">
                    {link.name}
                </Text>
            ) : link.isTitle && link.isCollapsible ? (
                <Flex align="center" onClick={onToggle} cursor="pointer" px="2">
                    <Text fontWeight="bold" flex="1">
                        {link.name}
                    </Text>
                    <IconButton
                        icon={isOpen ? <FiChevronDown /> : <FiChevronRight />}
                        variant="ghost"
                        size="sm"
                        aria-label="Toggle Menu"
                        onClick={onToggle}
                    />
                </Flex>
            ) : (
                <Box
                    as={Link}
                    to={link.to || '#'}
                    style={{ textDecoration: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                    w="full"
                >
                    <Flex
                        align="center"
                        px="4"
                        py="3"
                        borderRadius="lg"
                        role="group"
                        cursor="pointer"
                        bg={active ? '#ebf1f9' : 'transparent'}
                        color={active ? 'black' : 'inherit'}
                        _hover={{
                            bg: '#ebf1f9',
                            color: 'black',
                        }}
                        w="full"
                        lineHeight="95%"
                        textAlign="left"
                        whiteSpace="normal"
                    >
                        {link.name}
                    </Flex>
                </Box>
            )}
            {link.children && (
                <Collapse in={isOpen} animateOpacity>
                    <VStack align="start" pl={4} spacing={0.5} w="full">
                        {link.children.map((child) => (
                            <NavItem key={child.name} link={child} />
                        ))}
                    </VStack>
                </Collapse>
            )}
        </Box>
    );
};
